import React from 'react'
import logo from './asset/shree.png'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div><div className="container-fluid bg-dark text-light mt-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container">
        <div className="row gx-5">
          <div className="col-lg-4 col-md-6 footer-about">
            <div className="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-primary p-4">
              <a href="index.html" className="navbar-brand">
                <img src={logo} alt="Shree Logo" style={{ width: '80px', height: '80px' }} />
              </a>
              <p className="mt-3 mb-4">Shree Digital Marketing Agency specializes in 360-degree digital marketing, providing tailored solutions to help brands scale up.</p>
              <form action="">
                <div className="input-group">
                  <input type="text" className="form-control border-white p-3" placeholder="Your Email" />
                  <button className="btn btn-dark">Sign Up</button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-8 col-md-6">
            <div className="row gx-5">
              <div className="col-lg-4 col-md-12 pt-5 mb-5">
                <div className="section-title section-title-sm position-relative pb-3 mb-4">
                  <h3 className="text-light mb-0">Get In Touch</h3>
                </div>
                <div className="d-flex mb-2">
                  <i className="bi bi-geo-alt text-primary me-2"></i>
                  <p className="mb-0">Hari House,Near Shivraje Washing Center,Neharu Nagar ,Jalgaon</p>
                </div>
                <div className="d-flex mb-2">
                  <i className="bi bi-envelope-open text-primary me-2"></i>
                  <p className="mb-0">buisness@shreedigimarketing.in</p>
                </div>
                <div className="d-flex mb-2">
                  <i className="bi bi-telephone text-primary me-2"></i>
                  <p className="mb-0">+917410726107</p>
                </div>
                <div className="d-flex mt-4">
                  <a className="btn btn-primary btn-square me-2" href="https://wa.link/xgzo1l"><i className="fab fa-twitter fw-normal"></i></a>
                  <a className="btn btn-primary btn-square me-2" href="https://www.facebook.com/people/Shree-Digital-Marketing-Agency/100089689839853/"><i className="fab fa-facebook-f fw-normal"></i></a>
                  <a className="btn btn-primary btn-square me-2" href="https://www.linkedin.com/in/devendra-waykole-b0554b219/?originalSubdomain=in"><i className="fab fa-linkedin-in fw-normal"></i></a>
                  <a className="btn btn-primary btn-square" href="https://www.instagram.com/shreedigitalmarketings/"><i className="fab fa-instagram fw-normal"></i></a>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                <div className="section-title section-title-sm position-relative pb-3 mb-4">
                  <h3 className="text-light mb-0">Quick Links</h3>
                </div>
                <div className="link-animated d-flex flex-column justify-content-start">
                  <Link className="text-light mb-2" to="/"><i className="bi bi-arrow-right text-primary me-2"></i>Home</Link>
                  <Link className="text-light mb-2" to="/about"><i className="bi bi-arrow-right text-primary me-2"></i>About Us</Link>
                  <Link className="text-light mb-2" to="/services"><i className="bi bi-arrow-right text-primary me-2"></i>Our Services</Link>
                  <Link className="text-light mb-2" to="/prices"><i className="bi bi-arrow-right text-primary me-2"></i>Latest Plans</Link>
                  <Link className="text-light" to="/contact"><i className="bi bi-arrow-right text-primary me-2"></i>Contact Us</Link>
                </div>
              </div>

              <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                <div className=" section-title-sm position-relative pb-3 mb-4">
                
                </div>
                <div className="d-flex flex-column justify-content-start">
                <Link className="text-light mb-2" to="/privacy">
  <i className="bi bi-shield-lock text-primary me-2"></i>Privacy Policy
</Link>
<Link className="text-light mb-2" to="/terms">
  <i className="bi bi-file-earmark-text text-primary me-2"></i>Terms and Conditions
</Link>
<Link className="text-light mb-2" to="/refund">
  <i className="bi bi-arrow-repeat text-primary me-2"></i>Refunds/Cancellation
</Link>                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <div className="container-fluid text-white" style={{ background: '#061429' }}>
        <div className="container text-center">
          <div className="row justify-content-end">
            <div className="col-lg-8 col-md-6">
              <div className="d-flex align-items-center justify-content-center" style={{ height: '75px' }}>
                <p className="mb-0">&copy; <a className="text-white border-bottom" href="https://wa.link/xgzo1l">Shree Digital Marketing Agency</a>. All Rights Reserved.

                  {/* This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. */}
                  Designed by <a className="text-white border-bottom" href="https://wa.link/xgzo1l">Shree Digital Marketing Agency</a></p>
              </div>
            </div>
          </div>
        </div>
      </div></div>
  )
}

export default Footer