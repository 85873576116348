import React from 'react';
import './privacy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <div className="privacy-policy-content">
        <h1>Privacy Policy</h1>
        <p>
          At Shree Digital Marketing Agency, accessible from shreedigitalmarketing.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that are collected and recorded by Shree Digital Marketing Agency and how we use it.
        </p>
        <p>
          If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
        </p>

        <h2>Consent</h2>
        <p>
          By using our website, you hereby consent to our Privacy Policy and agree to its terms.
        </p>

        <h2>Information we collect</h2>
        <p>
          The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.
        </p>

        <h2>How we use your information</h2>
        <p>
          We use the information we collect in various ways, including to:
        </p>
        <ul>
          <li>Provide, operate, and maintain our website</li>
          <li>Improve, personalize, and expand our website</li>
          <li>Understand and analyze how you use our website</li>
          <li>Develop new products, services, features, and functionality</li>
          <li>Communicate with you, including for customer service, updates, and promotional purposes</li>
          <li>Send you emails</li>
          <li>Find and prevent fraud</li>
        </ul>

        <h2>Log Files</h2>
        <p>
          Shree Digital Marketing Agency follows a standard procedure of using log files. These files log visitors when they visit websites, including internet protocol (IP) addresses, browser type, ISP, date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any personally identifiable information.
        </p>

        <h2>Advertising Partners</h2>
        <p>
          Some of the advertisers on our site may use cookies and web beacons. For more detailed information, you can refer to the Privacy Policies of our advertising partners.
        </p>

        <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>
        <p>
          Under the CCPA, California consumers have the right to request that a business disclose the categories and specific pieces of personal data that have been collected. For more information, please contact us.
        </p>

        <h2>GDPR Data Protection Rights</h2>
        <p>
          We would like to ensure that you are fully aware of your data protection rights. Every user is entitled to the following:
        </p>
        <ul>
          <li>The right to access, rectification, erasure, restriction, objection, and data portability.</li>
          <li>If you make a request, we have one month to respond to you.</li>
        </ul>

        <h2>Children’s Information</h2>
        <p>
          We encourage parents and guardians to monitor and guide their children's online activity. We do not knowingly collect personal information from children under 13. If you believe we have collected such information, please contact us.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
