import React from 'react';
import './Terms.css';

const Terms = () => {
  return (
    <div className="terms-container">
      <h1>Terms and Conditions</h1>
      <p className="intro">
        PLEASE READ THIS TERMS OF SERVICE AGREEMENT CAREFULLY. BY USING THIS WEBSITE OR ORDERING PRODUCTS FROM THIS WEBSITE, YOU AGREE TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT.
      </p>

      <section className="section">
        <h2>Agreement Overview</h2>
        <p>
          This Terms of Service Agreement (the “Agreement”) governs your use of this website, 
          <a href="https://shreedigimarketing.in/">https://shreedigimarketing.in/</a> (the “Website”), Shree Digital Marketing Agency (“Business Name”) offer of products, or your purchase of products available on this Website. Shree Digital Marketing Agency reserves the right to change or revise the terms at any time. 
        </p>
      </section>

      <section className="section">
        <h2>I. Products</h2>
        <h3>Terms of Offer</h3>
        <p>
          This Website offers for sale certain products (the “Products”). By placing an order, you agree to the terms set forth in this Agreement.
        </p>
        <h3>Customer Solicitation</h3>
        <p>
          Unless you opt-out, you agree to receive future emails and call solicitations from Shree Digital Marketing Agency and its designated team(s).
        </p>
        <h3>Opt-Out Procedure</h3>
        <p>
          You can opt-out through three methods:
          <ol>
            <li>Using the opt-out link in email solicitations.</li>
            <li>Emailing: <a href="mailto:business@shreedigimarketing.in">business@shreedigimarketing.in</a></li>
            <li>Sending a written request to our office address.</li>
          </ol>
        </p>
        <h3>Proprietary Rights</h3>
        <p>
          Shree Digital Marketing Agency has proprietary rights and trade secrets in the Products.
        </p>
      </section>

      <section className="section">
        <h2>II. Website Use</h2>
        <p>
          Your use of this Website and its content is for personal, non-commercial use. Unauthorized use of content may violate intellectual property laws.
        </p>
      </section>

      <section className="section">
        <h2>III. Disclaimer of Warranties</h2>
        <p>
          YOUR USE OF THIS WEBSITE AND/OR PRODUCTS IS AT YOUR SOLE RISK.Shree Digital Marketing Agency DISCLAIMS ALL WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
        </p>
      </section>

      <section className="section">
        <h2>IV. Limitation of Liability</h2>
        <p>
        Shree Digital Marketing Agency will not be liable for any direct, indirect, incidental, or consequential damages in connection with this Agreement.
        </p>
      </section>

      <section className="section">
        <h2>V. Indemnification</h2>
        <p>
          You agree to indemnify Shree Digital Marketing Agency from all liabilities, claims, damages, costs, and expenses related to your use of the Website or Products.
        </p>
      </section>

      <section className="section">
        <h2>VI. Privacy</h2>
        <p>
        Shree Digital Marketing Agency is committed to protecting your privacy. Refer to our <a href="/privacy">Privacy Policy</a> for more information.
        </p>
      </section>

      <section className="section">
        <h2>VII. Agreement to be Bound</h2>
        <p>
          By using this Website or ordering Products, you acknowledge that you have read and agree to be bound by this Agreement.
        </p>
      </section>

      <section className="section">
        <h2>VIII. General</h2>
        <p>
          This Agreement is governed by the laws of Maharashtra, India. You consent to the jurisdiction of Maharashtra courts in connection with any action related to this Agreement.
        </p>
      </section>
    </div>
  );
};

export default Terms;
